import styled, { css } from "styled-components"
import Theme, { getREMValues, lg, md, sm, xs } from "./theme"

export const PaddingMarginProps = css`
 ${props => props.center && "margin: 0 auto;"}
  padding-top: ${props => getREMValues(props.pt)};
  padding-left: ${props => getREMValues(props.pl)};
  padding-bottom: ${props => getREMValues(props.pb)};
  padding-right: ${props => getREMValues(props.pr)};
  padding: ${props => getREMValues(props.p)} ${props => getREMValues(props.p)};
  margin-top: ${props => getREMValues(props.mt)};
  margin-left: ${props => getREMValues(props.ml)};
  margin-bottom: ${props => getREMValues(props.mb)};
  margin-right: ${props => getREMValues(props.mr)};
  margin: ${props => getREMValues(props.m)} ${props => getREMValues(props.m)};
`
export const FontSizeProp = css`
  font-size: ${props => props.fontSize};
`
export const TextAlignProp = css`
  text-align: ${props => props.textAlign};
`
export const GridColumnsProp = css`
  grid-template-columns: ${props => props.gridTemplateColumns};
`

/** Components should intend to be re-usable **/
export const Button = styled.button`
  background: ${Theme.palette.text.primary};
  padding: 14px;
  border: none;
  border-radius: 5px;
  outline: 0;
  font-size: 32px;
  font-family: ${Theme.palette.font.body};
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  a {
    color: #fff;
  }
  width: 60%;
  ${PaddingMarginProps}
`
export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 45vh;
  margin: 0 auto;
  padding: 100px 0;
  background-color: ${Theme.palette.text.primary};
  .image {
    position: absolute;
    top: -30px;
    left: 5%;
    width: 90%;
    padding: 100px 0;
    background-color: white;
    z-index: 999;
  }
  img {
    display: block;
    width: 80%;
    margin: auto;
  }
  ${md} {
    height: 20vh;
  }
  ${xs} {
    height: 12vh;
  }
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const AppointmentContainer = styled(Container)`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  ${md} {
    width: 80%;
  }
  ${sm} {
    width: 90%;
    display: block;
    direction: ltr;
    div {
      margin-bottom: 0.5em;
    }
  }
  ${PaddingMarginProps}
`
export const AppointmentCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-gap: 3em;
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transform: scale(1.01);
    cursor: pointer;
  }
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const CardContent = styled.div`
  font-size: 14px;
  font-family: ${Theme.palette.font.body};
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 24px;
  }
  span {
    text-transform: uppercase;
  }
  p {
    margin-top: 10px;
    line-height: 150%;
  }
  ${sm} {
    .react-calendar {
      margin: auto;
    }
  }
  ${PaddingMarginProps}
`
export const AppointmentCardIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`
export const AppointmentSelect = styled(Container)`
  width: 60%;  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  ${md} {
    width: 70%;
  }
  ${sm} {
    width: 90%;
    display: block;
    direction: ltr;
    div {
      margin-bottom: 0.5em;
    }
    .react-calendar {
      margin-top: 3em;
    }
  }
  ${PaddingMarginProps}
  ${GridColumnsProp}
`
export const SelectButton = styled.button`
  background: ${Theme.palette.backgroundAlt};
  padding: 14px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-family: ${Theme.palette.font.body};
  cursor: pointer;
  color: ${Theme.palette.text.primary};
  width: 50%;
  margin: auto;
  outline: 0;
  :hover {
    transform: scale(1.01);
  }
  ${sm} {
    display: block;
    margin: 10px auto;
  }
`
export const PopoverContent = styled.div`
  width: 300px;
  font-size: 14px;
  font-family: ${Theme.palette.font.body};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  #popoverHeader {
    font-weight: 600;
    background: white;
    position: sticky;
    top: 0;
  }
  ${PaddingMarginProps}
`
export const PopoverButton = styled.button`
  background: ${Theme.palette.backgroundAlt};
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-family: ${Theme.palette.font.body};
  cursor: pointer;
  color: ${Theme.palette.text.primary};
  width: 60%;
  margin: 10px auto;
  padding: 6px;
  outline: 0;
  :focus {
    background: ${Theme.palette.button.primary};
    color: ${Theme.palette.text.secondary};
  }
`
export const Loader = styled.div`
  border: 4px solid ${Theme.palette.backgroundAlt};
  border-top: 4px solid ${Theme.palette.text.primary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 20px auto;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
export const SubmitContainer = styled.div`
  width: 60%;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  ${sm} {
    width: 90%;
    display: block;
    direction: ltr;
    div {
      margin-bottom: 0.5em;
    }
  }
  ${PaddingMarginProps}
  ${TextAlignProp}
`
export const SubmitForm = styled.form`
  margin: 0 auto;
  max-width: 600px;
  padding: 1.5em;
  border-radius: 10px;
  label {
    font-size: 12px;
  }
  input {
    border-radius: 5px;
    background-color: ${Theme.palette.background};
    height: 36px;
    margin: 10px 0;
    width: 90%;
    padding: 0 5%;
    border: none;
    font-family: ${Theme.palette.font.body};
    outline: 0;
  }
  input:focus {
    border: 1px solid ${Theme.palette.backgroundBlue};
  }
  [type="date"] {
    width: 95%;
    padding-right: 0px;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
  }
  select {
    border-radius: 5px;
    background-color: ${Theme.palette.background};
    height: 36px;
    margin: 10px 0;
    width: 100%;
    padding: 0 4%;
    border: none;
    font-family: ${Theme.palette.font.body};
    outline: 0;
    &:invalid {
      color: gray;
    }
  }
  button {
    display: flex;
    margin: 10px auto 0 auto;
  }
`
export const ScheduleContainer = styled(Container)`
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  ${sm} {
    width: 90%;
    display: block;
    direction: ltr;
    div {
      margin-bottom: 0.5em;
    }
  }
  ${PaddingMarginProps}
`
export const ScheduleCard = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transform: scale(1.01);
    cursor: pointer;
  }
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const SmallHeader = styled.h2`
  font-family: ${Theme.palette.font.body};
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const Article = styled.article`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const Flexbox = styled.div`
  display: flex;
  align-items: center;
  ${PaddingMarginProps}
`
export const ResponsiveFlexbox = styled(Flexbox)`
  ${sm} {
    flex-direction: column;
  }
`
export const ArticleFlexbox = styled(ResponsiveFlexbox)`
  align-items: stretch;
  ${md} {
    flex-direction: column;
  }
`
export const ArticleSidebar = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 70px;
  margin-bottom: 175px;
  padding: 0 40px;
  ${md} {
    margin-bottom: 30px;
  }
`
export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  width: 90%;
  max-width: 1232px;
  margin: 50px auto;
  a {
    color: #6895C8;
    font-size: 16px;
    font-weight: bold;
  }
  h3 {
    font-family: Gotham;
    font-size: 20px;
  }
  li {
    padding: 5px 0;
    font-size: 20px;
  }
  .card {
    border: 1px solid ${Theme.palette.text.primary};
    border-radius: 5px;
    padding: 10%;
  }
  ${md} {
    grid-column-gap: 30px;
    padding: 5%;
    img {
      width: 100px;
    }
    .card {
      border: none;
    }
  }
  ${sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      align-text: center;
    }
  }
`
export const Paragraph = styled.p`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const Span = styled.span`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const H1 = styled.h1`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const H2 = styled.h2`
  font-size: 52px;
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
  ${xs} {
    font-size: 42px;
  }
`
export const H3 = styled.h3`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const H4 = styled.h4`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const H5 = styled.h5`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const H6 = styled.h6`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const HR = styled.hr`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
  color: #979797;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
export const LocationModal = styled.div`
  position: absolute;
  background-color: ${Theme.palette.background};
  top: 75px;
  transform: ${props => `translate(${props.translation})`};
  z-index: 999;
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      padding: 15px 33px;
      &:hover {
        cursor: pointer;
        background-color: ${Theme.palette.backgroundAlt};
      }
    }
  }
  ${md} {
    position: static;
    transform: translate(-15px);
  }
`
export const ButtonContainer = styled(Container)`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  ${xs} {
    display: block;
  }
`
export const MenuButton = styled(Button)`
  margin: 10px;
  text-transform: uppercase;
  background-color: transparent;
  color: ${({ active = false }) =>
    active
      ? `${Theme.palette.text.red}; font-weight: bold`
      : Theme.palette.text.primary};
`
export const CTAButton = styled(Button)`
  padding: 18px 24px;
  font-size: 16px;
`
export const TransparentButton = styled(CTAButton)`
  background-color: transparent;
  a {
    color: ${Theme.palette.text.secondary};
  }
`
export const Input = styled.input`
  background-color: #fff;
  border: 1px solid #babec5;
  border-radius: 5px;
  padding: 12px 14px;
  font-size: 14px;
`
export const SubmitButton = styled(Button)`
  background: ${Theme.palette.button.secondary};
  padding: 14px 18px 14px 18px;
  margin-left: 16px;
`
export const SpanHeading = styled.span`
  color: ${Theme.palette.text.darkgray};
  font-size: 22px;
  text-transform: uppercase;
`
export const FeatureImageHero = styled.div`
  position: relative;
  max-height: 700px;
  overflow: hidden;
  height: 657px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .banner__image {
    margin: auto;
    width: 80%;
    padding: 10%;
  }
  background-color: #DEDFE1;
  section {
    padding-left: 100px;
    z-index: 3;
    background-color: #DEDFE1;
    color: ${Theme.palette.text.primary};
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;

      padding-bottom: 1em;
    }
    h1 {
      font-size: 64px;
      text-align: left;
      padding-bottom: 20px;
    }
    h3 {
      font-size: 32px;
      font-family: Gotham;
    }
    p {
      padding: 20px 0 0 0;
      width: 80%;
      max-width: 750px;
      font-size: 1.3rem;
    }
  }
  ${md} {
    section {
      h1 {
        font-size: 48px;
        padding-right: 180px;
      }
    }
  }
  ${sm} {
    display: flex;
    section {
      h1 {
        font-size: 34px;
      }
    }
  }
  ${xs} {
    min-height: 530px;
    max-height: none;
    flex-direction: column-reverse;
    section {
      height: 300%;
      padding: 0;
      display: block;
      h1 {
        width: 100%;
        font-size: 34px;
        text-align: center;
        align-self: start;
        padding: 30px 0 20px 0;
      }
      h3 {
        width: 100%;
        font-size: 24px;
        text-align: center;
        align-self: start;
        padding: 30px 0 20px 0;
      }
      p {
        text-align: center;
        font-size: 12px;
        padding: 10px 0;
        margin: 0 auto;
      }
      span {
        display: none;
      }
      button {
        font-size: 12px;
        width: 130px;
        transform: translate(-50%);
        position: relative;
        left: 50%;
        padding: 15px;
        margin-top: 10px;
      }
    }
  }
  @media (max-width: 390px) {
    p {
      font-size: 12px;
      line-height: 150%;
    }
  }
`
export const FeatureImageHeroMobile = styled.div`
  background-color: ${Theme.palette.text.primary};
  h2 {
    line-height: 100px;
    color: white;
  }
  .banner__image {
    width: 100%;
  }
  button {
    display: block;
    margin: 10% auto;
  }
`
export const FeatureImage = styled.div`
  position: absolute;
  width: 30vw;
  height: 30vw;
  max-height: 500px;
  max-width: 500px;
  right: 0;
  top: 50%;
  transform: translate(-70%, -50%);
  overflow: hidden;
  > div {
    height: 100% !important;
  }
  ${lg} {
  transform: translate(-50%, -50%);
  }
  ${xs} {
    width: 90vw;
    height: 80vw;
    transform: translate(-5vw, -10%);
  }
  @media (min-width: 2300px) {{
    transform: translate(-100%, -50%);
  }
  
`
export const FeatureHero = styled.div`
  position: relative;
  max-height: 700px;
  overflow: hidden;
  background-color: ${Theme.palette.background};
  section {
    width: 95vw;
    margin: 90px auto 0 auto;
    display: grid;
    template-columns: 1fr;
    align-items: center;
    span {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1em;
    }
    h1 {
      font-size: 64px;
      margin-bottom: 34px;
      text-align: center;
      max-width: 700px;
    }
    button {
      margin: 0 auto;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
      width: 80%;
      max-width: 800px;
      text-align: center;
    }
  }
  ${sm} {
    section {
      h1 {
        font-size: 56px;
      }
    }
  }
  ${xs} {
    height: auto;
    section {
      h1 {
        font-size: 42px;
        margin-bottom: 25px;
      }
      button {
        padding: 14px;
      }
    }
  }
  @media (max-width: 390px) {
    p {
      font-size: 12px;
      line-height: 150%;
    }
  }
`
export const BlogVideo = styled.div`
  z-index: 10;
  position: absolute;
  top: 20%;
  right: 10%;
  ${sm} {
    top: 30%;
  }
  ${xs} {
    top: 40%;
    right: 0%;
  }
`
export const NoMaxHeightFeatureHero = styled(FeatureHero)`
  max-height: none;
  ${xs} {
    height: inherit;
    max-height: none;
    .whatsapp-box {
      border-left: none !important;
    }
  }
`
export const SliderSlide = styled.div`
  width: 100%;
  height: auto;
`
export const TwoColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
  direction: ${props => props.reverse && "rtl"};
  margin: 100px auto;
  align-items: center;
  div {
    direction: ltr;
    img {
      width: 50%;
      display: block;
      margin: auto;
    }
  }
  a {
    color: #6895C8;
    font-size: 20px;
    font-weight: bold;
  }
  ${sm} {
    display: block;
    direction: ltr;
    div {
      margin-bottom: 0.5em;
    }
  }
`
export const ProtocolsContainer = styled(Container)`
  background: ${Theme.palette.backgroundDark};
  color: ${Theme.palette.text.secondary};
  width: 100%;
  padding: 80px 0;
  margin: 0;
  ul {
    list-style: none;
    margin-bottom: 50px;
    li {
      margin-bottom: 20px;
      font-size: 64px;
    }
  }
  a {
    color: ${Theme.palette.text.secondary};
    padding-left: 1.5rem;
  }
  ${xs} {
    ul {
      li {
        font-size: 32px;
      }
    }
  }
`
export const FeatureVideoAndBlogsContainer = styled(Container)`
  iframe {
    padding: 100px 0;
  }
  ${sm} {
    > div {
      display: block;
    }
  }
`
export const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns = "1fr 1fr 1fr 1fr" }) => columns};
  grid-gap: ${({ gap = "1.5em" }) => gap};
  ${sm} {
    grid-template-columns: ${({ smcolumns = "1fr 1fr" }) => smcolumns};
  }
  ${xs} {
    display: flex;
    flex-direction: column;
    justify-items: center;
    > div {
      margin-top: 1.5em;
    }
  }
  ${PaddingMarginProps}
`
export const MedicalAdvisorGrid = styled(ResponsiveGrid)`
  justify-items: center;
  max-width: 1000px;
  margin: 0 auto;
`
export const EventCard = styled.div`
  overflow: hidden;
  margin-bottom: 2em;
`
export const EventCardContent = styled.div`
  font-size: 14px;
  font-family: ${Theme.palette.font.body};
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin-top: 10px;
    font-size: 24px;
  }
  span {
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
  p {
    margin-top: 10px;
    font-weight: bold;
    line-height: 115%;
  }
  ${PaddingMarginProps}
`
export const BlogCard = styled.div`
  box-shadow: -1px 3px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  ${sm} {
    margin-bottom: 2em;
  }
`
export const CardImageWrapper = styled.div`
  height: ${({ height = "400px" }) => height};
  width: 100%;
  overflow: hidden;
  > div:first-child {
    height: ${({ height = "400px" }) => height} !important;
  }
  ${sm} {
    height: ${({ smheight = "220px" }) => smheight};
    > div:first-child {
      height: ${({ smheight = "220px" }) => smheight} !important;
    }
  }
`
export const TitleSection = styled.div`
  font-size: 14px;
  font-family: ${Theme.palette.font.body};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2em;
  height: ${({ height = "200px" }) => height};
  span {
    text-transform: uppercase;
    font-weight: 500;
  }
  h3 {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  ${xs} {
    span {
      font-size: 18px;
    }
    h3 {font-size: 24px !important;}
    padding 0 1em;
    height: 250px;
  }
`
export const ProviderCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 95% !important;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  hr {
    width: 90%;
    margin: 0 auto 18px auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  p {
    color: ${Theme.palette.text.darkgray};
  }
  > div:first-child {
    padding: 50px 1.5em 30px 1.5em;
    justify-self: center;
    display: flex;
    align-items: center;
    span {
      color: ${Theme.palette.text.darkgray};
      text-transform: uppercase;
      font-size: 14px;
    }
  }
  > div:last-child {
    width: 90%;
    margin: 0 auto;
  }
  .ProviderCard--title {
    margin-left: 30px;
  }
`
export const DarkContainer = styled(Container)`
  background: ${Theme.palette.backgroundBlack};
  width: 100%;
  color: white;
  text-align: center;
  h2 {
    padding-top: 2em;
    font-size: 64px;
  }
  h3 {
    font-size: 36px;
  }
  .wrapper {
    margin: 0 auto;
    width: 95%;
    max-width: 708px;
  }
  hr {
    width: 90%;
    max-width: 376px;
    margin: 0 auto 50px auto;
    border: 1px solid #979797;
  }
  h3 {
    margin: 0 auto;
    width: 80%;
    max-width: 832px;
  }
  p {
    margin: 0 auto;
    padding: 20px 0 5em 0;
  }
  ${sm} {
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size: 20px;
    }
  }
`
export const StatGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3em;
  margin: 125px auto;
  width: 90%;
  max-width: 1200px;
  ${sm} {
    grid-template-columns: 1fr 1fr;
  }
  ${xs} {
    display: block;
    > div {
      margin-top: 1.5em;
    }
  }
`
export const StatGridItem = styled.div`
  p {
    padding: 10px 0 0 0;
  }
  ${sm} {
    h3 {
      font-size: 28px;
    }
  }
`
export const TestimonialsContainer = styled(Container)`
  background: ${Theme.palette.backgroundDark};
  color: ${Theme.palette.text.secondary};
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 36px;
  span {
    font-size: 50px;

    font-family: ${Theme.palette.font.header};
  }
  .smaller {
    font-size: 45px;
  }
  p {
    width: 90% !important;
    max-width: 832px;
    margin: 0 auto;
    line-height: 115%;
  }
  ${sm} {
    font-size: 20px;
    span {
      font-size: 30px;
    }
    .smaller {
      font-size: 27px;
    }
  }
`
export const BlueQuote = styled.span`
  font-family: ${Theme.palette.font.header};
  color: ${Theme.palette.text.blue};
`
export const IndividualService = styled.div`
  font-family: "Gotham", serif;
  h1,
  h2,
  h3 {
    padding-top: 54px;
  }
  max-width: 1000px;
  margin: 0 auto 4em auto;
  a {
    color: ${Theme.palette.text.blue};
    font-weight: bold;
  }
`
export const ContactForm = styled.form`
  margin: 2em auto;
  width: 60%;
  border: 2px solid ${Theme.palette.text.primary};
  background-color: #f6f5f5;
  padding: 2.5em 5.5em;
  border-radius: 10px;
  h3 {
    text-align: center;
  }
  input,
  select {
    border-radius: 4px;
    background-color: ${Theme.palette.background};
    height: 36px;
    margin: 5px 0;
    width: 90%;
    padding: 0 5%;
    border: none;
  }
  select {
    width: 100%;
  }
  button {
    width: 25%;
    margin-top: 1em !important;
    float: center;
  }
  ${xs} {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 1.5em 0;
    input,
    select,
    button,
    div {
      max-width: 80vw !important;
    }
    input,
    span,
    button {
      margin-left: 5vw;
    }
    margin-bottom: 0;
    h2 {
      text-align: center;
    }
  }
  ${PaddingMarginProps}
`
export const Video = styled.div`
${sm} {  
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {  
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
`