const Theme = {
  palette: {
    background: "#FFFFFF",
    backgroundAlt: "#ECEEF1",
    backgroundDark: "#8D9096",
    backgroundBlack: "#393A3D",
    backgroundBlue: "#007AFF",
    text: {
      primary: "#122A47",
      secondary: "#FFFFFF",
      gray: "#D4D7DC",
      red: "#DB3E37",
      darkgray: "#8D9096",
      blue: "#007AFF",
    },
    font: {
      header: '"Frank Ruhl Libre", serif',
      body: '"Gotham", sans-serif',
    },
    button: {
      primary: "#007AFF",
      secondary: "#6B6C72",
      white: "#FFFFFF",
    },
  },
}
export default Theme

/** Pre-defined Breakpoints **/
export const xs = `@media (max-width: 599px)`
export const sm = `@media (max-width: 959px)`
export const md = `@media (max-width: 1279px)`
export const lg = `@media (max-width: 1919px)`
export const xl = `@media (min-width: 1920px)`

export const getREMValues = val => {
  switch (val) {
    case 0:
      return "0"
    case 1:
      return "0.5rem"
    case 2:
      return "1rem"
    case 3:
      return "1.5rem"
    case 4:
      return "2rem"
    case 5:
      return "3rem"
    default:
      return
  }
}
