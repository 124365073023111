import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  FeatureImageHero,
  H1,
  H3,
  ThreeColumnGrid,
  Button
} from "../components/styled/components"
import Fade from "react-reveal/Fade"
import hero from "../images/stop-the-spread.png"
import end_to_end from "../images/end-to-end.png"

const IndexPage = () => {
  const [active, setActive] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (active === 1) {
        setActive(2);
      } else if (active === 2) {
        setActive(3);
      } else if (active === 3) {
        setActive(1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [active]);

  return (
    <Layout>
      <SEO title="WellHealth - Official Kansas Vendor" />
      <FeatureImageHero>
        <section>
          {active === 1 &&
          <Fade>
            <div>
              <H1>
                Comply with OSHA Testing Mandates
              </H1>
              <H3>
                WellHealth makes it seamless.
              </H3>
            </div>
          </Fade>}
          {active === 2 &&
          <Fade>
            <div>
              <H1>
                WellHealth Knows Kansas
              </H1>
              <H3>
                Provided over 40 testing sites throughout The Great State of Kansas.
              </H3>
            </div>
          </Fade>}
          {active === 3 &&
          <Fade>
            <div>
              <H1>
                Well to Life
              </H1>
              <H3>
                A safe return to life for organizations, education systems, government agencies and more.
              </H3>
            </div>
          </Fade>}
          <Button
            mt={4}
            onClick={() => navigate("https://wellhealth.studio/contact/")}
          >
            PARTNER WITH US
          </Button>
        </section>
        <img className="banner__image" src={hero} alt="hero" />
      </FeatureImageHero>

      <Container>
        <div className="image">
          <img src={end_to_end} alt="Complete End-to-End Solution" />
        </div>
      </Container>

      <ThreeColumnGrid>
        <div className="card">
          <H3>Services</H3>
          <ul>
            <li>Testing (PCR, Rapid, &#38; Pool)</li>
            <li>Vaccinations</li>
            <li>Telemedicine</li>
            <li>Lab Services</li>
            <li>Monoclonal Antibody IV Therapy</li>
            <li>Medical Consultations</li>
            <li>Well to Life Occupational Health</li>
          </ul>
        </div>
        <div className="card">
          <H3>Administrative Support</H3>
          <ul>
            <li>Contact Tracing</li>
            <li>Customized Reporting</li>
            <li>Universal Software Integration</li>
            <li>Bilingual Call Center</li>
            <li>Population Health Management</li>
            <li>Public Health Information</li>
          </ul>
        </div>
        <div className="card">
          <H3>Deployment Options</H3>
          <ul>
            <li>Mobile - RVs</li>
            <li>Static</li>
            <li>Hub and Spoke</li>
            <li>At Home (Virtual)</li>
          </ul>
        </div>
      </ThreeColumnGrid>

      <div className="container">
        <ul>
          <li>
            <b>Turnkey:</b> Rapidly deployable, completely hands-off solution to comply with testing mandates.
          </li>
          <li>
            <b>Streamlined:</b> Automated integration and compliance reporting for staffing and attendee groups, registration and patient record keeping for ease of access.
          </li>
          <li>
            <b>Case Management:</b> Complete AI driven - identify vaccination status and those needing regular testing Positive patients receive virtual medical consults. Symptom screening, positive case treatment, contact tracing, prolonged disease management recommending quarantine periods.
          </li>
          <li>
            <b>Full Spectrum Covid Care:</b> Deliver PCR &#38; rapid testing methodologies, monoclonal antibody treatments, and vaccinations. FDA/EUA approved.
          </li>
          <li>
            <b>Privacy:</b> Provide HIPAA compliant results through text message and email.
          </li>
          <li>
            <b>Results Delivered:</b> We have consistently delivered turnkey testing management for government agencies, cooperations, education systems, travel, healthcare systems, music and sporting events.
          </li>
          <li>
            <b>Data Management:</b> Integrated reporting solutions to HR departments, event coordinators and state agencies to insure compliance with testing mandates.
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage
