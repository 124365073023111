import styled from "styled-components"
import Theme, { sm, xs } from "./theme"

/** Header **/
export const Navbar = styled.header`
  width: 100%;
  display: block;
  top: 0;
  z-index: 999;
  background-color: ${Theme.palette.text.primary};

  .logo {
    text-align: center;
    padding: 0.5%;
  }
  .header {
    color: white;
    text-align: center;
    padding: 0.5%;
    text-decoration: underline;
  }
`

export const Hamburger = styled.div`
  background-color: ${Theme.palette.text.primary};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${Theme.palette.text.primary};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

export const NavModal = styled.div`
  position: fixed;
  width: 20%;
  height: 100%;
  box-shadow: -2px 0 3px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  background-color: #fff;
  transition: all 1000ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  top: 0;
  padding-top: 35px;
  right: ${props => (props.open ? "-100%" : "0")};
  z-index: 999;
  div:first-child {
    margin-left: 1em;
  }
  .links {
    margin-left: 1em;
    padding-top: 100px;
    flex-direction: column;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1em;
    justify-content: flex-start;
  }
  svg {
    margin-right: 3px;
  }
  button {
    color: ${Theme.palette.text.primary};
    background-color: transparent;
    padding: 0 0 5px 0;
    font-size: 16px;
  }

  ${sm} {
    width: 50%;
  }
  ${xs} {
    width: 70%;
  }
`

/** Footer **/
export const FooterContainer = styled.footer`
  background: ${Theme.palette.text.primary};
  color: ${Theme.palette.text.secondary};
`
export const FooterSection = styled.div`
  width: 90%;
  margin: 0 auto;
  border-bottom: 2px solid #6b6c72;
`
export const FooterCTA = styled(FooterSection)`
  font-size: 48px;
  text-align: center;
  padding: 80px 0 76px 0;
  button {
    margin-top: 30px;
  }
  ${xs} {
    font-size: 30px;
  }
`
export const FooterSubscribe = styled(FooterSection)`
  padding: 50px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  h3 {
    font-size: 40px;
    padding-left: 3rem;
  }
  form {
    h4 {
      font-size: 22px;
    }
    p {
      color: ${Theme.palette.text.gray};
      font-size: 15px;
    }
  }
  ${sm} {
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      padding-left: 0;
      font-size: 38px;
      margin-bottom: 0.5em;
    }
  }
`
export const FooterMenuSection = styled(FooterSection)`
  padding: 84px 0 95px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  h4 {
    font-size: 18px;
    margin-bottom: 35px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin: 20px 0;
      a {
        font-size: 16px;
        color: ${Theme.palette.text.gray};
      }
    }
    :last-child {
      li:last-child {
        a {
          margin-right: 12px;
        }
      }
    }
  }
  ${xs} {
    display: none;
  }
`
export const FooterCopyrightSection = styled(FooterSection)`
  padding: 30px 0;
  border-bottom: none;
  display: block;
  div:first-child {
    display: flex;
    align-items: center;
    p {
      margin-left: 2em;
    }
  }
  div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      color: ${Theme.palette.text.gray};
      margin: 0 25px;
    }
  }
  ${sm} {
    display: block;
    .gatsby-image-wrapper {
      margin: 0 auto;
    }
    div:first-child {
      padding: 0;
      flex-direction: column;
      p {
        font-size: 11px;
        text-align: center;
        margin-left: 0;
      }
    }
    div:last-child {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.5em;
      margin: 1em auto;
      font-size: 12px;
    }
  }
`
