import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Navbar } from "./styled/layout"
import { H1 } from "./styled/components"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      file(relativePath: { eq: "wellhealth-logo-white.png" }) {
        childImageSharp {
          fixed(width: 190, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Navbar>
      <div className="logo">
        <Link to="/" aria-label="Home">
          <Img fixed={data.file.childImageSharp.fixed} />
        </Link>
      </div>
      <div className="header">
        <H1>Complete COVID Care - Official Kansas Vendor</H1>
      </div>
    </Navbar>
  )
}

export default Header
