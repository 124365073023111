import { createGlobalStyle } from "styled-components"
import Theme, { xs } from "./theme"

const GlobalStyleInjector = createGlobalStyle`

  @import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Baskervville&display=swap");
  body {
    font-family: Gotham, sans-serif;
    color: ${Theme.palette.text.primary};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${Theme.palette.font.header};
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 52px;
    ${xs} {
      font-size: 42px;
    }
  }
  a {
    text-decoration: none;
    color: ${Theme.palette.text.primary};
  }
  p {
    line-height: 200%;
  }
  .slick-next {
    position: absolute;
    right: 0;
    transform: scale(3) translate(-50%);
    
    border-radius: 50%;
    z-index: 5;
    ::before {
      color: ${Theme.palette.text.primary} !important;
    }
  }
  .slick-prev {
    position: absolute;
    left: 0;
    transform: scale(3) translate(50%);
    z-index: 4;
    ::before {
      color: ${Theme.palette.text.primary} !important;
    }
  }
  ${xs} {
    .slick-next {
      transform: scale(1.5) translate(-25%);
    }
    .slick-prev {
      transform: scale(1.5) translate(25%);
    }
  }
  .accordion {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border-left: none;
      border-right: none;
  }
  .accordion__item + .accordion__item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .accordion__button {
      background-color: ${Theme.palette.background};
      color: ${Theme.palette.text.primary};
      cursor: pointer;
      padding: 28px 0;
      width: 100%;
      text-align: left;
      border: none;
      font-size: 22px;
      font-weight: bold;    
  }
  .accordion__button:hover {
      background-color: #f4f4f4;
  }
  .accordion__button:before {
      display: inline-block;
      content: '';
      height: 10px;
      width: 10px;
      margin-right: 12px;
      transform: rotate(-45deg);
  }
  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
      transform: rotate(45deg);
  }
  .accordion__panel {
      padding: 20px;
      animation: fadein 0.35s ease-in;
  }
  ${xs} {
    .accordion__button {
      font-size: 20px;
    }
  }

  .container {
    border: 1px solid ${Theme.palette.text.primary};
    border-radius: 5px;
    width: 80%;
    margin: 10% auto;
    padding: 2.5%;
    li {
      padding: 5px 0;
      font-size: 20px;
    }
    line-height: 2em;
  }
  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  
  @keyframes fadein {
      0% {
          opacity: 0;
      }
  
      100% {
          opacity: 1;
      }
  }
`

export default GlobalStyleInjector
