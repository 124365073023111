import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  FooterContainer,
  FooterCopyrightSection,
} from "./styled/layout"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      file(relativePath: { eq: "wellhealth-logo-white.png" }) {
        childImageSharp {
          fixed(width: 190, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <FooterContainer>
      <FooterCopyrightSection>
        <div>
          <Link to="https://wellhealth.studio">
            <Img fixed={data.file.childImageSharp.fixed} />
          </Link>
          <p>
            © {new Date().getFullYear()}, WellHealth, LLC. All rights reserved.
          </p>
        </div>
      </FooterCopyrightSection>
    </FooterContainer>
  )
}

export default Footer
